import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API, { BASE_URL } from "utils/service";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  allCountry: [],
  allCity: [],
  allStates: [],
  Applications: [],
  ApplicationSubmitResponse: {},
  isApplicationSubmitLoading: false,
  allCampaignPlans: [],
  plansresponse: [],
  allInsurancePlans: [],
  installmentPeriod: [],
  sendInstallmentPeriod: [],
  loanApplication: {},
  allStatuses: [],
  allInstalmentPlans: [],
  allPlansDropDown: [],
  singlePlans: [],
  image: "",
  loanApplicationDeviceLock: {},
  tasdeeqResponse: {},
  DashboardStatsResponse: [],
  DeviceDashboardStatsResponse: {},
  deviceImeisList: [],
  isOtpSend: false,
  Lisence: {},
  allTasdeeq: [],
  singleApplicationStatus: {},
  singlefinanceApplicationStatus: {},
  singleInstalmentPeriodType: {},
  allFinancingGroup: [],
  allFinancingCompany: [],
  allUOM: [],
  tempPlanDropdown: [],
  productDetails: {},
  promocodeStatus: [],
  alldocumentTypeDropdown: [],
  allStatesDropdown: [],
  insuranceData: {},
  createInsuranceResponse: {},
  getInsuranceDataLoading: false,
  createInsuranceLoading: false,
  updateUrlLoading: false,
  insuranceListing: [],
  totalElements: 0,
};

// GET Insurance Data
export const getInsuranceData = createAsyncThunk(
  "finance/getInsuranceData",
  async (appID, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/device-management/v1/device-property/insurance/data?appId=${appID}`
      );
      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error fetching insurance data"
      );
    }
  }
);

// POST Insurance Data
export const createInsurance = createAsyncThunk(
  "finance/createInsurance",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/device-management/v1/device-property/insurance/create`,
        data
      );
      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Error creating insurance"
      );
    }
  }
);

export const getAllCountry = createAsyncThunk(
  "miscellaneous/allCountry",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`${BASE_URL}/miscellaneous/v1/country`);
      let value = response?.data?.payload?.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getUOM = createAsyncThunk(
  "miscellaneous/v1/uom ",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`${BASE_URL}/miscellaneous/v1/uom `);
      let value = response?.data?.payload?.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAllDeviceIMEI = createAsyncThunk(
  "miscellaneous/allEMEI",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/device-management/v1/device-property`
      );
      let value = response?.data?.payload?.map((item) => ({
        label: item.value, // Assuming 'name' is another property
        value: item.id,
        key: `${item.key}`, // Creating a unique key
      }));
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const UpdateIMEI = createAsyncThunk(
  "miscellaneous/UpdateIMEI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `/device-management/v1/device-property/update-device-by-order`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const UnlockDevice = createAsyncThunk(
  "miscellaneous/UnlockDevice",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `/device-management/v1/device/unlock/${id}`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const LockDevice = createAsyncThunk(
  "miscellaneous/LockDevice",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `/device-management/v1/device/lock/${id}`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const SyncDevice = createAsyncThunk(
  "device-management/syncDevice",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `/device-management/v1/device/pay-trigger/check-lock-state/batch`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const LockDevicePayTrigger = createAsyncThunk(
  "miscellaneous/LockDevice-pt",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `/device-management/v1/device/lock-unlock-pt/${id}`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const ReleaseDevicePayTrigger = createAsyncThunk(
  "/remove-device",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `/device-management/v1/device/remove-device`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const UploadProfile = createAsyncThunk(
  "/orders/osi-order",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `/order-management/orders/osi-order`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const UploadProfileBulk = createAsyncThunk(
  "/orders/osi-order-bulk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `/order-management/orders/osi-order/bulk`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const DashboardStats = createAsyncThunk(
  "miscellaneous/dashboard",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`miscellaneous/v1/dashboard`);
      let value = response?.data?.payload;
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const DeviceDashboardStats = createAsyncThunk(
  "miscellaneous/dashboard-device",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`miscellaneous/v1/dashboard/device`);
      let value = response?.data?.payload;
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAllCity = createAsyncThunk(
  "miscellaneous/allCity",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`${BASE_URL}/miscellaneous/v1/city`);
      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getAllState = createAsyncThunk(
  "miscellaneous/allState",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`${BASE_URL}/miscellaneous/v1/state`);
      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const MerchantLisence = createAsyncThunk(
  "/query-merchant-licences",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/device-management/v1/device/query-merchant-licences?organizationName=${params}`
      );
      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getAllFinancingApplication = createAsyncThunk(
  "finance/v1/financing-applications/list",
  async (params, { rejectWithValue }) => {
    try {
      let paramsCheck = params.status ? `&statusId=${params.status}` : "";
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";

      let sortParam = "";
      if (
        params.column &&
        params.column !== "" &&
        params.order &&
        params.order !== ""
      ) {
        sortParam = `&sort=${params.column},${params.order}`;
      }

      const response = await API.get(
        `/finance/v1/financing-applications/list?page=${params.page}${paramsCheck}${updatedByParam}${startDateParam}${query}${endDateParam}${sortParam}`
      );
      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAllFinancingApplicationNew = createAsyncThunk(
  "finance/v1/financing-applications/list-new",
  async (params, { rejectWithValue }) => {
    try {
      let paramsCheck = params.status ? `&statusId=${params.status}` : "";
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";

      let sortParam = "";
      if (
        params.column &&
        params.column !== "" &&
        params.order &&
        params.order !== ""
      ) {
        sortParam = `&sort=${params.column},${params.order}`;
      }

      const response = await API.get(
        `/finance/v1/financing-applications-new/list?page=${params.page}${paramsCheck}${updatedByParam}${startDateParam}${query}${endDateParam}${sortParam}`
      );
      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const DeleteFinancingApplicationChannels = createAsyncThunk(
  "finance/financing-application-channels-delete",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `/finance/v1/financing-application-channels/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const DeleteFinancingApplicationStatus = createAsyncThunk(
  "finance/financing-application-statuses-delete",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `/finance/v1/financing-application-statuses/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getSingleApplicationChannel = createAsyncThunk(
  "financing/ApplicationChannel-by-id",
  async (param, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/finance/v1/financing-application-channels/${param}`
      );
      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getSingleInstalmentPeriodById = createAsyncThunk(
  "financing/installment-period-types-by-id",
  async (param, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/finance/v1/installment-period-types/${param}`
      );
      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getSingleApplicationStatus = createAsyncThunk(
  "financing/financeApplicationStatus-by-id",
  async (param, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/finance/v1/financing-application-statuses/${param}`
      );
      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getAllBlogs = createAsyncThunk(
  "/miscellaneous/v1/blog/list",
  async (params, { rejectWithValue }) => {
    try {
      let paramsCheck = params.status ? `&statusId=${params.status}` : "";
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";

      let sortParam = "";
      if (
        params.column &&
        params.column !== "" &&
        params.order &&
        params.order !== ""
      ) {
        sortParam = `&sort=${params.column},${params.order}`;
      }

      const response = await API.get(
        `/miscellaneous/v1/blog/list?page=${params.page}${paramsCheck}${updatedByParam}${startDateParam}${query}${endDateParam}${sortParam}`
      );
      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAllWhatsappFinancingApplication = createAsyncThunk(
  "finance/v1/whatsapp-financing-applications/list",
  async (params, { rejectWithValue }) => {
    try {
      let paramsCheck = params.status ? `&statusId=${params.status}` : "";
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";

      let sortParam = "";
      if (
        params.column &&
        params.column !== "" &&
        params.order &&
        params.order !== ""
      ) {
        sortParam = `&sort=${params.column},${params.order}`;
      }

      const response = await API.get(
        `miscellaneous/application/whatsapp?page=${params.page}${paramsCheck}${updatedByParam}${startDateParam}${query}${endDateParam}${sortParam}`,
        {
          headers: {
            Accept: "",
            "Content-Type": "application/json",
          },
        }
      );
      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAllTasdeeqList = createAsyncThunk(
  "tasdeeq/list",
  async (params, { rejectWithValue }) => {
    try {
      let paramsCheck = params.status ? `&statusId=${params.status}` : "";
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";

      let sortParam = "";
      if (
        params.column &&
        params.column !== "" &&
        params.order &&
        params.order !== ""
      ) {
        sortParam = `&sort=${params.column},${params.order}`;
      }

      const response = await API.get(
        `/kyc/tasdeeq/list?page=${params.page}${paramsCheck}${updatedByParam}${startDateParam}${query}${endDateParam}${sortParam}`
      );
      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getDataCheckList = createAsyncThunk(
  "data-check/list",
  async (params, { rejectWithValue }) => {
    try {
      let paramsCheck = params.status ? `&statusId=${params.status}` : "";
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";

      let sortParam = "";
      if (
        params.column &&
        params.column !== "" &&
        params.order &&
        params.order !== ""
      ) {
        sortParam = `&sort=${params.column},${params.order}`;
      }

      const response = await API.get(
        `/kyc/data-check/list?page=${params.page}${paramsCheck}${updatedByParam}${startDateParam}${query}${endDateParam}${sortParam}`
      );
      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getDataCheckByID = createAsyncThunk(
  "data-check/new/{id}",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`/kyc/data-check/new/${params}`);
      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getTasdeeqByID = createAsyncThunk(
  "tasdeeq/new/",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`/kyc/tasdeeq/new/${params}`);
      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const DeleteDataCheckReport = createAsyncThunk(
  "data-check/delete/{id}",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(`/kyc/data-check/${params}`);
      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const DeleteTasdeeqReport = createAsyncThunk(
  "tasdeeq/delete/{id}",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(`/kyc/tasdeeq/${params}`);
      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAllFinancingApplicationOutBack = createAsyncThunk(
  "order-management/orders/list",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";

      let sortParam = `&sort=${params.column &&
        params.column !== "" &&
        params.order &&
        params.order !== ""
        ? `${params.column},${params.order}`
        : "o.id,desc"
        }`;

      const response = await API.get(
        `/order-management/orders/list?page=${params.page}${updatedByParam}${startDateParam}${query}${endDateParam}${sortParam}`
      );

      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const GetAllReleaseDevicesListOutBack = createAsyncThunk(
  "order-management/orders/release-list",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";

      let sortParam = `&sort=${params.column &&
        params.column !== "" &&
        params.order &&
        params.order !== ""
        ? `${params.column},${params.order}`
        : "o.id,desc"
        }`;

      const response = await API.get(
        `/order-management/orders/release-list?page=${params.page}${updatedByParam}${startDateParam}${query}${endDateParam}${sortParam}`
      );

      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const SubmitApplication = createAsyncThunk(
  "user-management/SubmitApplication",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/user-management/v1/financing/submit-application-new`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const SubmitUserConsent = createAsyncThunk(
  "user-management/SubmitUserConsent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/user-management/v1/financing/terms-and-conditions`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const SyncPing = createAsyncThunk(
  "/device-management/v1/device/device-server-sync-ping",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/device-management/v1/device/device-server-sync-ping`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const SubmitApplicationStatus = createAsyncThunk(
  "finance/financing-application-statuses",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-application-statuses
        `,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const EditApplicationStatus = createAsyncThunk(
  "finance/edit-financing-application-statuses",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-application-statuses/${id}
        `,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const CalculatePlans = createAsyncThunk(
  "finance/financing-application-statuses",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `https://kfp7wy27nxi3xqij2mptmhld7q0jeund.lambda-url.me-south-1.on.aws/calculate`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const JazzPairing = createAsyncThunk(
  "auth/pairing/jazz",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/authentication/v1/pairing/jazz`, data);

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const UfonePairing = createAsyncThunk(
  "auth/pairing/ufone",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/authentication/v1/pairing/ufone`, data);

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const ZongPairing = createAsyncThunk(
  "auth/pairing/zong",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/authentication/v1/pairing/zong`, data);

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const ExportData = createAsyncThunk(
  "/finance/v1/financing-applications/export-all",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `/finance/v1/financing-applications/export-all`
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const TelenorPairing = createAsyncThunk(
  "auth/pairing/telenor",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `/authentication/v1/pairing/telenor`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getAllPlans = createAsyncThunk(
  "finance/financing-plans",
  async (params, { rejectWithValue }) => {
    try {
      let paramsCheck = params.status ? `&campaignId=${params.status}` : "";
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/finance/v1/financing-plans/list?page=${params.page
        }${updatedByParam}${startDateParam}${paramsCheck}${query}${endDateParam}${params.column !== "" && params.order !== ""
          ? `&sort=${params.column},${params.order}`
          : ""
        }`
      );

      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAllPromoCodes = createAsyncThunk(
  "finance/get-promo-codes",
  async (params, { rejectWithValue }) => {
    try {
      // let paramsCheck = params.status ? `&campaignId=${params.status}` : "";
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/finance/promo-codes/list?page=${params.page
        }${updatedByParam}${startDateParam}${query}${endDateParam}${params.column !== "" && params.order !== ""
          ? `&sort=${params.column},${params.order}`
          : ""
        }`
      );

      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getAllInsurance = createAsyncThunk(
  "finance/insurance-plans-get-all",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(`/finance/v1/insurance-plans`);

      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const DeletePlan = createAsyncThunk(
  "finance/delete-financing-plans",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `/finance/v1/financing-plans/${params}`
      );

      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const GetPromoCodeById = createAsyncThunk(
  "finance/getpromo-code-by-id",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`/finance/promo-codes/${params}`);

      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const DeleteBanner = createAsyncThunk(
  "v1/banner/delete",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(`/miscellaneous/v1/banner/${params}`);

      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const EditBanner = createAsyncThunk(
  "v1/banner/update",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(`/miscellaneous/v1/banner/${id}`, data);

      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const DeletePlanBulk = createAsyncThunk(
  "finance/delete-financing-plans-bulk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `/finance/v1/financing-plans/delete/bulk`,
        data
      );

      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getAllPlansDropDown = createAsyncThunk(
  "finance/financing-plans-dropDown",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/finance/v1/financing-plans/list?size=1000`
      );
      let value = response?.data?.payload.content.map((element) => {
        return { value: element?.id, label: element?.name };
      });
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAllPromoCodesStatus = createAsyncThunk(
  "finance/promo-code-statuses",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`${BASE_URL}/finance/promo-code-statuses`);
      let value = response?.data?.payload.map((element) => {
        return { value: element?.id, label: element?.name };
      });
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const DeletePromoCode = createAsyncThunk(
  "finance/delete-promocode",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(`/finance/promo-codes/${params}`);

      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAllPlansDropDownTemp = createAsyncThunk(
  "finance/financing-plans-dropDown-temp",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/finance/v1/financing-plans/list?size=1000`
      );
      let value = response?.data?.payload.content.map((element) => {
        return { value: element?.id, label: element?.name };
      });
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getSinglePlan = createAsyncThunk(
  "finance/single-financing-plan",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/finance/v1/financing-plans/${params.id}`
      );
      let value = response?.data?.payload;
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const GeneratePlans = createAsyncThunk(
  "finance/financing-plans/generatePlans",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-plans`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const GeneratePromoCodes = createAsyncThunk(
  "finance/financing-plans/promo-codes",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`${BASE_URL}/finance/promo-codes`, data);

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const UpdatePromoCodes = createAsyncThunk(
  "finance/financing-plans/promo-codes-update",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/finance/promo-codes/${id}`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const GeneratePlansBulk = createAsyncThunk(
  "finance/financing-plans/generatePlans-Bulk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-plans/save/bulk`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const PostPlan = createAsyncThunk(
  "finance/financing-plans/plan-post",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-plans`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

// Document Type POST
export const DocumentTypePost = createAsyncThunk(
  "finance/Document-Type/POST",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/document-type`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
// Document checklist POST
export const DocumentCheckListPost = createAsyncThunk(
  "finance/document-checklist/POST",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/document-checklist`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

// Document checklist PATCH
export const DocumentCheckListPatch = createAsyncThunk(
  "finance/document-checklist/PATCH",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/finance/v1/document-checklist/${id}`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
// Document Type PATCH
export const DocumentTypePatch = createAsyncThunk(
  "finance/Document-Type/PATCH",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/finance/v1/document-type/${id}`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

// Document Type DELETE
export const DeleteDocumentType = createAsyncThunk(
  "finance/Document-Type/DELETE",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `${BASE_URL}/finance/v1/document-type/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

// Document Type GET
export const GetAllDocumentTypes = createAsyncThunk(
  "finance/document-type/GET",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/finance/v1/document-type/list?page=${params.page
        }${updatedByParam}${startDateParam}${query}${endDateParam}${params.column !== "" && params.order !== ""
          ? `&sort=${params.column},${params.order}`
          : "&sort=id,desc"
        }`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const GetAllDocumentTypesDropDown = createAsyncThunk(
  "finance/document-type-dropdown/GET",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`/finance/v1/document-type`);

      return response?.data?.payload.map((item) => {
        return {
          label: item?.documentTypeName,
          value: item?.id,
        };
      });
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const GetAllStatesDropDown = createAsyncThunk("finance/state-dropdown/GET", async (params, { rejectWithValue }) => {
  try {
    const response = await API.get(`/finance/v1/application-state`);

    return response?.data?.payload.map((item) => {
      return {
        label: item?.name,
        value: item?.id,
      };
    });
  } catch (err) {
    return rejectWithValue(err.response?.data?.message || "Error");
  }
}
);

//Document verification checklist get
export const GetAllDocumentCheckList = createAsyncThunk(
  "finance/document-checklist/GET",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/finance/v1/document-checklist/list?page=${params.page
        }${updatedByParam}${startDateParam}${query}${endDateParam}${params.column !== "" && params.order !== ""
          ? `&sort=${params.column},${params.order}`
          : "&sort=id,desc"
        }`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

//Document verification checklist get
export const GetAllStatuses = createAsyncThunk(
  "finance/status/GET",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/finance/v1/application-status/list?page=${params.page
        }${updatedByParam}${startDateParam}${query}${endDateParam}${params.column !== "" && params.order !== ""
          ? `&sort=${params.column},${params.order}`
          : "&sort=id,desc"
        }`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

//Document Reject reason checklist get
export const GetAllDocumentRejectReason = createAsyncThunk(
  "finance/reject-reason/GET",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/finance/v1/reject-reason/list?page=${params.page
        }${updatedByParam}${startDateParam}${query}${endDateParam}${params.column !== "" && params.order !== ""
          ? `&sort=${params.column},${params.order}`
          : "&sort=id,desc"
        }`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
//Document Reject reason checklist delete
export const DeleteDocumentRejectReason = createAsyncThunk(
  "finance/reject-reason/DELETE",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `${BASE_URL}/finance/v1/reject-reason/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

// Document Reject reason PATCH
export const DocumentRejectReasonPatch = createAsyncThunk(
  "finance/reject-reason/PATCH",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/finance/v1/reject-reason/${id}`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

// Document checklist POST
export const DocumentRejectReasonPost = createAsyncThunk(
  "finance/reject-reason/POST",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/reject-reason`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

// Document CheckList DELETE
export const DeleteDocumentCheckList = createAsyncThunk(
  "finance/document-checklist/DELETE",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `${BASE_URL}/finance/v1/document-checklist/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

// Document Decision Change Reason POST
export const DocumentChangeReasonPost = createAsyncThunk(
  "finance/Document-Change-Reason/POST",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/change-decision-reason`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

// Document Decision Change Reason GET
export const GetAllDocumentChangeReasons = createAsyncThunk(
  "finance/Document-Change-Reason/GET",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/finance/v1/change-decision-reason/list?page=${params.page
        }${updatedByParam}${startDateParam}${query}${endDateParam}${params.column !== "" && params.order !== ""
          ? `&sort=${params.column},${params.order}`
          : "&sort=id,desc"
        }`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

// Document Decision Change Reason PATCH
export const DocumentChangeReasonPatch = createAsyncThunk(
  "finance/Document-Change-Reason/PATCH",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/finance/v1/change-decision-reason/${id}`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

// Document Type DELETE
export const DeleteDocumentChangeReason = createAsyncThunk(
  "finance/Document-Change-Reason/DELETE",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `${BASE_URL}/finance/v1/change-decision-reason/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

// Document resubmission Reason POST
export const DocumentResubmissionReasonPost = createAsyncThunk(
  "finance/Document-Resubmission-Reason/POST",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/resubmission-reason`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

// Document resubmission Reason GET
export const GetAllDocumentResubmissionReasons = createAsyncThunk(
  "finance/Document-Resubmission-Reason/GET",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/finance/v1/resubmission-reason/list?page=${params.page
        }${updatedByParam}${startDateParam}${query}${endDateParam}${params.column !== "" && params.order !== ""
          ? `&sort=${params.column},${params.order}`
          : "&sort=id,desc"
        }`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

// Document resubmission Reason PATCH
export const DocumentResubmissionReasonPatch = createAsyncThunk(
  "finance/Document-Resubmission-Reason/PATCH",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/finance/v1/resubmission-reason/${id}`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

// Document resubmission Reason DELETE
export const DeleteDocumentResubmissionReason = createAsyncThunk(
  "finance/Document-Resubmission-Reason/DELETE",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `${BASE_URL}/finance/v1/resubmission-reason/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const UpdatePlan = createAsyncThunk(
  "finance/financing-plans/plan-update",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/finance/v1/financing-plans/${id}`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const UpdatePlans = createAsyncThunk(
  "finance/financing-plans/updatePlans",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/finance/v1/financing-plans/${id}`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const GetInstallmentPeriod = createAsyncThunk(
  "finance/get-installment-period",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/finance/v1/installment-period-types`,
        data
      );
      let value = response?.data?.payload.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const installmentPeriodType = createAsyncThunk(
  "finance/get-installment-period-post",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/installment-period-types`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const EditInstallmentPeriodType = createAsyncThunk(
  "finance/edit-installment-period-types",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/finance/v1/installment-period-types/${id}`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const financingApplicationChannels = createAsyncThunk(
  "finance/post-application-statuses",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-application-channels`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const EditfinancingApplicationChannels = createAsyncThunk(
  "finance/edit-application-statuses",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/finance/v1/financing-application-channels/${id}`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const installmentStatuses = createAsyncThunk(
  "finance/post-installment-statuses",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-installment-statuses`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getAllInsurancePlan = createAsyncThunk(
  "finance-management/insurance-plan",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`${BASE_URL}/finance/v1/insurance-plans`);
      let value = response?.data?.payload?.map((item) => ({
        label: item?.name,
        value: item.insuranceId,
      }));

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getFinancingGroup = createAsyncThunk(
  "finance-management/v1/financing-group",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`${BASE_URL}/finance/v1/financing-group`);
      let value = response?.data?.payload?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getFinancingCompany = createAsyncThunk(
  "finance-management/v1/financing-comopany",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/finance/v1/financing-comopany`
      );
      let value = response?.data?.payload?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getFinancingCompanyListing = createAsyncThunk(
  "finance/insurance-plans-get-all-paginated",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/finance/v1/financing-comopany/list?page=${params.page
        }${updatedByParam}${startDateParam}${query}${endDateParam}${params.column !== "" && params.order !== ""
          ? `&sort=${params.column},${params.order}`
          : ""
        }`
      );

      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const CreateFinancingGroup = createAsyncThunk(
  "finance-management/create-financing-group",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-group`,
        params
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const UpdateFinancingGroup = createAsyncThunk(
  "finance-management/create-financing-group",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/finance/v1/financing-group/${id}`,
        data
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const DeletGroup = createAsyncThunk(
  "finance-management/delete-financing-group",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `${BASE_URL}/finance/v1/financing-group/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const CreateFinancingCompany = createAsyncThunk(
  "finance-management/v1/financing-comopany-create",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-comopany`,
        params
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const postInsurancePlan = createAsyncThunk(
  "finance-management/insurance-plan",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/insurance-plans`,
        params
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const postReferenceStatus = createAsyncThunk(
  "finance-management/post-reference-status",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/user-management/v1/consumer-reference-status`,
        params
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getApplicationStatus = createAsyncThunk(
  "finance-management/financing-application-statuses",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/finance/v1/financing-application-statuses`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const ReferenceApproval = createAsyncThunk(
  "finance-management/application-reference-approval",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/finance/v1/financing-applications/application-reference-approval`
      );
      let value = response?.data?.payload?.map((item) => ({
        label: item.insuranceCharges,
        value: item.id,
      }));

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getLoanApplicationData = createAsyncThunk(
  "finance-management/financing-application-loan",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/finance/v1/financing-applications/${params.id}`
      );

      return response?.data?.payload === null ? {} : response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getLoanApplicationDataNew = createAsyncThunk(
  "finance-management/financing-application-loan-new",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/finance/v1/financing-applications-new/${params.id}`
      );

      return response?.data?.payload === null ? {} : response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const RevisedPlan = createAsyncThunk(
  "financing-plans/revise-plan",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/finance/v1/financing-plans/revise-plan?planId=${params.planId
        }${params.applicationPublicId === ""
          ? ""
          : `&applicationPublicId=${params.applicationPublicId}`
        }`
      );

      return response?.data?.payload === null ? {} : response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const RevisedPlanNew = createAsyncThunk(
  "financing-plans/revise-plan-new",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/finance/v1/financing-plans-new/revise-plan?planId=${params.planId
        }${params.applicationPublicId === ""
          ? ""
          : `&applicationPublicId=${params.applicationPublicId}`
        }`
      );

      return response?.data?.payload === null ? {} : response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getLoanApplicationDataDeviceLock = createAsyncThunk(
  "finance-management/financing-application-device-lock-payments",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/finance/v1/financing-applications-new/device-lock-details/${params.id}`
      );
      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getLoanApplicationDataDeviceLockOld = createAsyncThunk(
  "finance-management/financing-application-device-lock-payments-old",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/finance/v1/financing-applications/device-lock-details/${params.id}`
      );
      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const VerifyRefrence = createAsyncThunk(
  "finance/application-reference-approval",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-applications/application-reference-approval`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
// Refrence Question Delete
export const DeleteReferenceQuestions = createAsyncThunk(
  "v1/app-references-delete",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `/finance/v1/app-references/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const RefrenceQuestion = createAsyncThunk(
  "v1/app-references-question-post",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/app-references`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const GetAllRefrenceQuestions = createAsyncThunk(
  "/v1/app-references-get",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/finance/v1/app-references/list?page=${params.page
        }${updatedByParam}${startDateParam}${query}${endDateParam}${params.column !== "" && params.order !== ""
          ? `&sort=${params.column},${params.order}`
          : "&sort=id,desc"
        }`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const UpdateRefrenceQuestion = createAsyncThunk(
  "v1/app-references-patch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/finance/v1/app-references/${data?.id}`,
        data.data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

// Refrence Question Delete
export const DeleteReferenceRelation = createAsyncThunk(
  "v1/relations-delete",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `/finance/v1/relations/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const RefrenceRelation = createAsyncThunk(
  "v1/relations-question-post",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/relations`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const GetAllRefrenceRelation = createAsyncThunk(
  "/v1/relations-get",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/finance/v1/relations/list?page=${params.page
        }${updatedByParam}${startDateParam}${query}${endDateParam}${params.column !== "" && params.order !== ""
          ? `&sort=${params.column},${params.order}`
          : "&sort=id,desc"
        }`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const UpdateRefrenceRelation = createAsyncThunk(
  "v1/relations-patch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/finance/v1/relations/${data?.id}`,
        data.data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const VerifyRefrenceNew = createAsyncThunk(
  "finance/application-reference-approval-new",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-applications-new/application-reference-approval`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const MarkinvoicesPayment = createAsyncThunk(
  "payment/v1/invoices/mark-payment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/payment/v1/invoices/mark-payment`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const MarkinvoicesPaymentNew = createAsyncThunk(
  "payment/v1/invoices/mark-payment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/payment/v1/invoices/mark-payment-new`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const UpdateDocument = createAsyncThunk(
  "v1/user-document",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `/user-management/v1/user-document/${id}`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const AddNewInstalment = createAsyncThunk(
  "finance/v1/finance-management/mark-payment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-installments`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const DeleteInstalment = createAsyncThunk(
  "finance/v1/finance-management/DeleteInstalment",
  async (id, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `${BASE_URL}/finance/v1/financing-installments/${id}`
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const EarlyPayOffInstallment = createAsyncThunk(
  "finance/v1/financing-installments/early-payoff",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-installments/early-payoff`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const EarlyPayOffInstallmentNew = createAsyncThunk(
  "finance/v1/financing-installments/early-payoff-new",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-installments/early-payoff-new`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const DeviceActivation = createAsyncThunk(
  "finance/v1/financing-device-activation",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-applications/device-activation`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const DeviceActivationNew = createAsyncThunk(
  "finance/v1/financing-device-activation-new",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-applications-new/device-activation`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const EditPayment = createAsyncThunk(
  "finance/application-edit-payment",
  async ({ pathValue, data }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/finance/v1/financing-installments/${pathValue}`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const GetImagebyID = createAsyncThunk(
  "miscellaneous/image",
  async (pathValue, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/miscellaneous/image/${pathValue}`
      );

      return response?.data?.payload?.url;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const CreateShipment = createAsyncThunk(
  "miscellaneous/bluex/send-request",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/miscellaneous/v1/bluex/send-request`,
        data
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const ChangeInstallmentDate = createAsyncThunk(
  "finance/application-change-installment-date",

  async ({ pathValue, data }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/finance/v1/financing-installments/${pathValue}`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const MarkPayment = createAsyncThunk(
  "finance/mark-payment",

  async ({ pathValue, data }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/finance/v1/financing-installments/${pathValue}`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getAllApplicationStatusDropDown = createAsyncThunk(
  "finance/financing-application-statuses",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/finance/v1/financing-application-statuses`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getAllInstalmentList = createAsyncThunk(
  "finance/get-all-instalment-list",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      const response = await API.get(
        `/finance/v1/financing-installments/list?page=${params.page
        }${updatedByParam}${startDateParam}${endDateParam}${params.column !== "" && params.order !== ""
          ? `&sort=${params.column},${params.order}`
          : ""
        }`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const ApplicationStatusApprove = createAsyncThunk(
  "finance/application-approval",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-applications/application-approval`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const ApplicationStatusApproveNew = createAsyncThunk(
  "finance/application-approval-new",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-applications-new/application-approval`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const postAcceptCIR = createAsyncThunk(
  "finance/accept-application-statuses",
  async ({ pathValue, data }, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-applications/update-application-status`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const postAcceptCIRNew = createAsyncThunk(
  "finance/accept-application-statuses-new",
  async ({ pathValue, data }, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-applications-new/update-application-status`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const postRejectCIR = createAsyncThunk(
  "finance/reject-application-statuses",
  async ({ pathValue, data }, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-applications/update-application-status`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const postRejectCIRNew = createAsyncThunk(
  "finance/reject-application-statuses-new",
  async ({ pathValue, data }, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/finance/v1/financing-applications-new/update-application-status`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const postAcceptDocuments = createAsyncThunk(
  "finance/application-accept-document",
  async ({ pathValue, data }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/user-management/v1/user-document/document-approval/${pathValue}`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const postAcceptDocumentsNew = createAsyncThunk(
  "finance/application-accept-document-new",
  async ({ pathValue, data }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/user-management/v1/user-document/document-approval-new/${pathValue}`,
        data
      );

      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const Tasdeeq = createAsyncThunk(
  "kyc/tasdeeq",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`kyc/tasdeeq`, data);

      return JSON.parse(response?.data?.payload?.jsonResponse);
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const VerifyOtp = createAsyncThunk(
  "user-management/verify-otp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/user-management/v1/otp/verify-otp`,
        data
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const SentOtp = createAsyncThunk(
  "user-management/sent-otp",
  async ({ data, dispatch }, { rejectWithValue }) => {
    try {
      dispatch(setisOtpSend(false));
      const response = await API.post(
        `${BASE_URL}/user-management/v1/otp/send-otp`,
        data
      );

      if (response.data.success === true && response.status === 200) {
        dispatch(setisOtpSend(true));
      }

      return response?.data?.payload;
    } catch (err) {
      dispatch(setisOtpSend(false));
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const PostBanner = createAsyncThunk(
  "miscellaneous/banner-website",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/miscellaneous/v1/banner`,
        data
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const Banners = createAsyncThunk(
  "miscellaneous/all-banner-website",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/miscellaneous/v1/banner`,
        data
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const GetToken = createAsyncThunk(
  "authentication/validate-session",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/authentication/v1/auth/validate-session`,
        data
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const GetStoreProduct = createAsyncThunk(
  "product-management/v1/product/list/filteration",
  async (params, { rejectWithValue }) => {
    try {
      let query = params.search ? `&query=${params.search}` : "";
      // Initialize the base URL and parameters
      // let url = `${BASE_URL}/product-management/v1/product/list?page=${data.page}&size=9&campaignId=20&isActive=${true}`;
      const response = await API.get(
        `/product-management/v1/product/list?page=${params.page
        }${query}&size=25&campaignId=${params?.id}&isActive=${true}`
      );

      // Return the response data
      return response?.data?.payload;
    } catch (err) {
      // Handle errors
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const GetProductDetailsById = createAsyncThunk(
  "/product-management/v1/product",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/product/product-details?urlKey=${data.url}&campaignId=${data?.id}`
      );

      // Return the response data or some part of it as needed
      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

// state and status slices

// application status POST
export const CreateApplicationStatus = createAsyncThunk('/finance/status/POST', async (data, { rejectWithValue }) => {
  try {
    const response = API.post(`${BASE_URL}/finance/v1/application-status`, data);
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data?.message)
  }
})

// application status PATCH
export const UpdateApplicationStatus = createAsyncThunk('/finance/status/PATCH', async ({ data, id }, { rejectWithValue }) => {
  try {
    const response = API.patch(`${BASE_URL}/finance/v1/application-status/${id}`, data)
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data?.message)
  }
})

// application status DELETE
export const DeleteApplicationStatus = createAsyncThunk('/finance/status/DELETE', async (id, { rejectWithValue }) => {
  try {
    const response = API.delete(`${BASE_URL}/finance/v1/application-status/${id}`);
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data?.message)
  }
})

// application state POST
export const CreateApplicationState = createAsyncThunk('/finance/state/POST', async (data, { rejectWithValue }) => {
  try {
    const response = API.post(`${BASE_URL}/finance/v1/application-state`, data);
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data?.message)
  }
})

// application state PATCH
export const UpdateApplicationState = createAsyncThunk('/finance/state/PATCH', async ({ data, id }, { rejectWithValue }) => {
  try {
    const response = API.patch(`${BASE_URL}/finance/v1/application-state/${id}`, data)
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data?.message)
  }
})

// application status DELETE
export const DeleteApplicationState = createAsyncThunk('/finance/state/DELETE', async (id, { rejectWithValue }) => {
  try {
    const response = API.delete(`${BASE_URL}/finance/v1/application-state/${id}`);
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data?.message)
  }
})


export const verifyImeiAllocation = createAsyncThunk(
  "miscellaneous/verifyImeiAllocation",
  async (imei, { rejectWithValue }) => {
    try {
      const response = await API.get(`${BASE_URL}/device-management/v1/device-property/imei/${imei}`);

      if (response.status === 204) {
        return { status: 'ERROR', message: 'IMEI does not exist, please enter correct IMEI.' };
      }

      if (response.data.success) {
        return {
          status: 'SUCCESS',
          message: 'IMEI is available to map.',
          payload: response.data.payload
        };
      }
    } catch (err) {

      if (err.response.status === 409) {
        return { status: 'ERROR', message: err.response.data.message };
      }
      else {
        return rejectWithValue(err.response?.data?.message || "Error");
      }

    }
  }
);



// Fetch insurance listing
// export const fetchInsuranceListing = createAsyncThunk(
//   "finance/fetchInsuranceListing",
//   async (params, { rejectWithValue }) => {
//     try {
//       // Commented out API call for now
//       // const response = await API.get(`${BASE_URL}/insurance/listing`, { params });
//       // return response.data;

//       // Mock data
//       return {
//         data: [
//           {
//             id: 1,
//             fullName: "John Doe",
//             phoneNumber: "0300-1234567",
//             publicId: "ID: fg5467",
//             tenure: 6,
//             deviceName: "Samsung A54, 8GB/256GB",
//             createdDate: "2024-06-01T12:00:00Z",
//             phoneValue: 50000,
//             policyNumber: "1234567890",
//           },
//           {
//             id: 2,
//             fullName: "Jane Smith",
//             phoneNumber: "0300-7654321",
//             publicId: "ID: fg5468",
//             tenure: 12,
//             deviceName: "iPhone 13, 128GB",
//             createdDate: "2024-06-01T13:30:00Z",
//             phoneValue: 75000,
//             policyNumber: "0987654321",
//           },
//         ],
//         totalElements: 2,
//       };
//     } catch (err) {
//       return rejectWithValue(err.response?.data?.message || "Error fetching insurance listing");
//     }
//   }
// );

// export const GetAllDocumentCheckList = createAsyncThunk(
//   "finance/document-checklist/GET",
//   async (params, { rejectWithValue }) => {
//     try {
//       let startDateParam = params.startDate
//         ? `&startDate=${params.startDate}`
//         : "";
//       let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
//       let updatedByParam = params.updatedBy
//         ? `&filterBy=${params.updatedBy}`
//         : "";
//       let query = params.search ? `&query=${params.search}` : "";
//       const response = await API.get(
//         `/finance/v1/document-checklist/list?page=${params.page
//         }${updatedByParam}${startDateParam}${query}${endDateParam}${params.column !== "" && params.order !== ""
//           ? `&sort=${params.column},${params.order}`
//           : "&sort=id,desc"
//         }`
//       );

//       return response?.data?.payload;
//     } catch (err) {
//       return rejectWithValue(err.response?.data?.message || "Error");
//     }
//   }
// );


export const fetchInsuranceListing = createAsyncThunk(
  "finance/fetchInsuranceListing",
  async (params, { rejectWithValue }) => {
    try {
      let queryParams = `page=${params.page || 0}`;

      if (params.startDate) {
        queryParams += `&startDate=${params.startDate}`;
      }
      if (params.endDate) {
        queryParams += `&endDate=${params.endDate}`;
      }
      if (params.query) {
        queryParams += `&query=${params.query}`;
      }
      if (params.isNotBooked !== undefined) {
        queryParams += `&isNotBooked=${params.isNotBooked}`;
      }

      const response = await API.get(
        `${BASE_URL}/device-management/v1/device-property/insurance/data/list?${queryParams}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error fetching insurance listing");
    }
  }
);

// Update the updateInsuranceUrl action
export const updateInsuranceUrl = createAsyncThunk(
  "finance/updateInsuranceUrl",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/device-management/v1/device-property/insurance/update-url`,
        {
          applicationPublicId: data.applicationPublicId,
          insuranceUrl: data.insuranceUrl
        }
      );
      if (response.data.success) {
        return response.data;
      }
      return rejectWithValue(response.data.message || "Failed to update URL");
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error updating URL");
    }
  }
);


export const PostUploadIMEIJazz = createAsyncThunk(
  "v1/device/upload-device",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/device-management/v1/device/upload-device`,
        data
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);


export const financeSlice = createSlice({
  name: "finance",
  initialState,
  reducers: {
    setPayment: (state, action) => {
      state.loanApplication.financingInstallments = action.payload;
    },
    setisOtpSend: (state, action) => {
      state.isOtpSend = action.payload;
    },
    setUpdatedfinancingDetailResponse: (state, action) => {
      state.loanApplication.financingDetailResponse = action.payload;
    },
    setRemoveLoanApplicationData: (state, action) => {
      state.loanApplication = action.payload;
    },

    updateDocumentApprovalStatus: (state, action) => {
      const { userDocumentId, remarks } = action.payload;
      const documentIndex = state?.loanApplication?.userDocuments?.findIndex(
        (doc) => doc.userDocumentId === userDocumentId
      );

      if (documentIndex !== -1) {
        state.loanApplication.userDocuments[
          documentIndex
        ].documentApprovalStatus = 1;
      }
    },
    updateDocumentRemarks: (state, action) => {
      const { userDocumentId, remarks } = action.payload;
      const documentIndex = state.loanApplication.userDocuments.findIndex(
        (doc) => doc.userDocumentId === userDocumentId
      );

      if (documentIndex !== -1) {
        state.loanApplication.userDocuments[
          documentIndex
        ].userDocumentLogs.push({
          remarks: remarks,
          userDocumentId: userDocumentId,
          // You might want to set the appropriate documentApprovalStatusId here
        });
      }
    },
    updateImageState: (state, action) => {
      state.image = action.payload;
    },
    // other reducers...
  },

  extraReducers: (builder) => {
    builder
      .addCase(getInsuranceData.pending, (state) => {
        state.loading = true;
        state.getInsuranceDataLoading = true;
      })
      .addCase(getInsuranceData.fulfilled, (state, action) => {
        state.loading = false;
        state.getInsuranceDataLoading = false;
        state.insuranceData = action.payload;
      })
      .addCase(getInsuranceData.rejected, (state, action) => {
        state.loading = false;
        state.getInsuranceDataLoading = false;
        state.message = action.payload;
        state.showMessage = true;
      })
      .addCase(createInsurance.pending, (state) => {
        state.loading = true;
        state.createInsuranceLoading = true;
      })
      .addCase(createInsurance.fulfilled, (state, action) => {
        state.loading = false;
        state.createInsuranceLoading = false;
        state.createInsuranceResponse = action.payload;
      })
      .addCase(createInsurance.rejected, (state, action) => {
        state.loading = false;
        state.createInsuranceLoading = false;
        state.message = action.payload;
        state.showMessage = true;
      })
      .addCase(getAllCountry.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCountry.fulfilled, (state, action) => {
        state.loading = false;
        state.allCountry = action.payload;
      })
      .addCase(getAllCountry.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllCity.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCity.fulfilled, (state, action) => {
        state.loading = false;
        state.allCity = action.payload;
      })
      .addCase(getAllCity.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllState.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllState.fulfilled, (state, action) => {
        state.loading = false;
        state.allStates = action.payload;
      })
      .addCase(getAllState.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllFinancingApplication.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllFinancingApplication.fulfilled, (state, action) => {
        state.loading = false;
        state.Applications = action.payload;
      })
      .addCase(getAllFinancingApplication.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(SubmitApplication.pending, (state) => {
        state.loading = true;
        state.isApplicationSubmitLoading = true;
      })
      .addCase(SubmitApplication.fulfilled, (state, action) => {
        state.loading = false;
        state.ApplicationSubmitResponse = action.payload;
        state.isApplicationSubmitLoading = false;
      })
      .addCase(SubmitApplication.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.isApplicationSubmitLoading = false;
      })
      .addCase(getAllPlans.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.allCampaignPlans = action.payload;
      })
      .addCase(getAllPlans.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.isApplicationSubmitLoading = false;
      })
      .addCase(GeneratePlans.pending, (state) => {
        state.loading = true;
      })
      .addCase(GeneratePlans.fulfilled, (state, action) => {
        state.loading = false;
        state.plansresponse = action.payload;
      })
      .addCase(GeneratePlans.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.isApplicationSubmitLoading = false;
      })
      .addCase(getAllInsurancePlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllInsurancePlan.fulfilled, (state, action) => {
        state.loading = false;
        state.allInsurancePlans = action.payload;
      })
      .addCase(getAllInsurancePlan.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.isApplicationSubmitLoading = false;
      })
      .addCase(GetInstallmentPeriod.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetInstallmentPeriod.fulfilled, (state, action) => {
        state.loading = false;
        state.installmentPeriod = action.payload;
      })
      .addCase(GetInstallmentPeriod.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.isApplicationSubmitLoading = false;
      })
      .addCase(installmentPeriodType.pending, (state) => {
        state.loading = true;
      })
      .addCase(installmentPeriodType.fulfilled, (state, action) => {
        state.loading = false;
        state.sendInstallmentPeriod = action.payload;
      })
      .addCase(installmentPeriodType.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.isApplicationSubmitLoading = false;
      })
      .addCase(getLoanApplicationData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLoanApplicationData.fulfilled, (state, action) => {
        state.loading = false;
        state.loanApplication = action.payload;
      })
      .addCase(getLoanApplicationData.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getLoanApplicationDataNew.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLoanApplicationDataNew.fulfilled, (state, action) => {
        state.loading = false;
        state.loanApplication = action.payload;
      })
      .addCase(getLoanApplicationDataNew.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllApplicationStatusDropDown.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllApplicationStatusDropDown.fulfilled, (state, action) => {
        state.loading = false;
        state.allStatuses = action.payload;
      })
      .addCase(getAllApplicationStatusDropDown.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllInstalmentList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllInstalmentList.fulfilled, (state, action) => {
        state.loading = false;
        state.allInstalmentPlans = action.payload;
      })
      .addCase(getAllInstalmentList.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllPlansDropDown.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPlansDropDown.fulfilled, (state, action) => {
        state.loading = false;
        state.allPlansDropDown = action.payload;
      })
      .addCase(getAllPlansDropDown.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getSinglePlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSinglePlan.fulfilled, (state, action) => {
        state.loading = false;
        state.singlePlans = action.payload;
      })
      .addCase(getSinglePlan.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(GetImagebyID.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetImagebyID.fulfilled, (state, action) => {
        state.loading = false;
        state.image = action.payload;
      })
      .addCase(GetImagebyID.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getLoanApplicationDataDeviceLock.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLoanApplicationDataDeviceLock.fulfilled, (state, action) => {
        state.loading = false;
        state.loanApplicationDeviceLock = action.payload;
      })
      .addCase(getLoanApplicationDataDeviceLock.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getLoanApplicationDataDeviceLockOld.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getLoanApplicationDataDeviceLockOld.fulfilled,
        (state, action) => {
          state.loading = false;
          state.loanApplicationDeviceLock = action.payload;
        }
      )
      .addCase(
        getLoanApplicationDataDeviceLockOld.rejected,
        (state, action) => {
          state.message = action.payload;
          state.showMessage = true;
          state.loading = false;
        }
      )
      .addCase(Tasdeeq.pending, (state) => {
        state.loading = true;
      })
      .addCase(Tasdeeq.fulfilled, (state, action) => {
        state.loading = false;

        state.tasdeeqResponse = action.payload;
      })
      .addCase(Tasdeeq.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(DashboardStats.pending, (state) => {
        state.loading = true;
      })
      .addCase(DashboardStats.fulfilled, (state, action) => {
        state.loading = false;

        state.DashboardStatsResponse = action.payload;
      })
      .addCase(DashboardStats.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(DeviceDashboardStats.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeviceDashboardStats.fulfilled, (state, action) => {
        state.loading = false;

        state.DeviceDashboardStatsResponse = action.payload;
      })
      .addCase(DeviceDashboardStats.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllDeviceIMEI.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDeviceIMEI.fulfilled, (state, action) => {
        state.loading = false;

        state.deviceImeisList = action.payload;
      })
      .addCase(getAllDeviceIMEI.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(MerchantLisence.pending, (state) => {
        state.loading = true;
      })
      .addCase(MerchantLisence.fulfilled, (state, action) => {
        state.loading = false;

        state.Lisence = action.payload;
      })
      .addCase(MerchantLisence.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllTasdeeqList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTasdeeqList.fulfilled, (state, action) => {
        state.loading = false;

        state.allTasdeeq = action.payload;
      })
      .addCase(getAllTasdeeqList.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getSingleApplicationChannel.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleApplicationChannel.fulfilled, (state, action) => {
        state.loading = false;
        state.singleApplicationStatus = action.payload;
      })
      .addCase(getSingleApplicationChannel.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getSingleApplicationStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleApplicationStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.singlefinanceApplicationStatus = action.payload;
      })
      .addCase(getSingleApplicationStatus.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getSingleInstalmentPeriodById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleInstalmentPeriodById.fulfilled, (state, action) => {
        state.loading = false;
        state.singleInstalmentPeriodType = action.payload;
      })
      .addCase(getSingleInstalmentPeriodById.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getFinancingGroup.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFinancingGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.allFinancingGroup = action.payload;
      })
      .addCase(getFinancingGroup.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getFinancingCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFinancingCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.allFinancingCompany = action.payload;
      })
      .addCase(getFinancingCompany.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getUOM.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUOM.fulfilled, (state, action) => {
        state.loading = false;
        state.allUOM = action.payload;
      })
      .addCase(getUOM.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllPlansDropDownTemp.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPlansDropDownTemp.fulfilled, (state, action) => {
        state.loading = false;
        state.tempPlanDropdown = action.payload;
      })
      .addCase(getAllPlansDropDownTemp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(GetProductDetailsById.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetProductDetailsById.fulfilled, (state, action) => {
        state.loading = false;
        state.productDetails = action.payload;
      })
      .addCase(GetProductDetailsById.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllPromoCodesStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPromoCodesStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.promocodeStatus = action.payload;
      })
      .addCase(getAllPromoCodesStatus.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(GetAllDocumentTypesDropDown.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllDocumentTypesDropDown.fulfilled, (state, action) => {
        state.loading = false;
        state.alldocumentTypeDropdown = action.payload;
      })
      .addCase(GetAllDocumentTypesDropDown.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(GetAllStatesDropDown.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllStatesDropDown.fulfilled, (state, action) => {
        state.loading = false;
        state.allStatesDropdown = action.payload;
      })
      .addCase(GetAllStatesDropDown.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(verifyImeiAllocation.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyImeiAllocation.fulfilled, (state, action) => {
        state.loading = false;
        // You can add any additional state updates here if needed
      })
      .addCase(verifyImeiAllocation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchInsuranceListing.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInsuranceListing.fulfilled, (state, action) => {
        state.loading = false;
        state.insuranceListing = action.payload?.payload?.content || [];
        state.totalElements = action.payload?.payload?.totalElements || 0;
      })
      .addCase(fetchInsuranceListing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })


      // Update URL cases
      .addCase(updateInsuranceUrl.pending, (state) => {
        state.updateUrlLoading = true;
      })
      .addCase(updateInsuranceUrl.fulfilled, (state) => {
        state.updateUrlLoading = false;
      })
      .addCase(updateInsuranceUrl.rejected, (state) => {
        state.updateUrlLoading = false;
      });

  },
});

export const {
  setPayment,
  setUpdatedfinancingDetailResponse,
  setRemoveLoanApplicationData,
  updateDocumentApprovalStatus,
  updateDocumentRemarks,
  updateImageState,
  setisOtpSend,
} = financeSlice.actions;

export default financeSlice.reducer;
