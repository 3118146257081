import React from "react";
import {
  AUTH_PREFIX_PATH,
  APP_PREFIX_PATH,
  EXTERNAL_PREFIX_PATH,
  APP_PREFIX_PATH_SETTINGS,
} from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "tnc",
    path: `${EXTERNAL_PREFIX_PATH}/terms-and-conditions`,
    component: React.lazy(() => import("views/external-views/tnc")),
  },
  {
    key: "business-to-business",
    path: `${EXTERNAL_PREFIX_PATH}/addCampaignApplication`,
    component: React.lazy(() =>
      import("views/external-views/BusinessToBusiness")
    ),
  },
  {
    key: "user-roles",
    path: `${AUTH_PREFIX_PATH}/user-roles`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/user-roles")
    ),
  },
  {
    key: "ufone",
    path: `${EXTERNAL_PREFIX_PATH}/ufone`,
    component: React.lazy(() => import("views/external-views/Ufone")),
  },
  {
    key: "ufone-application",
    path: `${EXTERNAL_PREFIX_PATH}/ufone/application`,
    component: React.lazy(() =>
      import("views/external-views/Ufone/UfoneApplication")
    ),
  },
  {
    key: "partner-store",
    path: `${EXTERNAL_PREFIX_PATH}/global-store`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/ExternalStore")
    ),
  },
  {
    key: "b2b-store",
    path: `${EXTERNAL_PREFIX_PATH}/business-store`,
    component: React.lazy(() => import("views/app-views/e-commerce/b2bstore")),
  },
  {
    key: "product-plans-ufone-id",
    path: `${EXTERNAL_PREFIX_PATH}/product/:id`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/ExternalProductPlan")
    ),
  },
  {
    key: "jazz-imei",
    path: `${EXTERNAL_PREFIX_PATH}/upload-imei`,
    component: React.lazy(() =>
      import("views/external-views/UploadImei/index")
    ),
  },
];

// Protected routes that require authentication
export const protectedRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },

  {
    key: "dlc-view",
    path: `${APP_PREFIX_PATH}/DLC/:id`,
    component: React.lazy(() => import("views/app-views/DLC")),
  },
  {
    key: "device-logs",
    path: `${APP_PREFIX_PATH}/devices-logs`,
    component: React.lazy(() => import("views/app-views/DeviceLogs")),
  },
  {
    key: "sidenav.logs",
    path: `${APP_PREFIX_PATH}/devices-logs/:id`,
    component: React.lazy(() =>
      import("views/app-views/DeviceLogs/LogsDetail")
    ),
  },

  {
    key: "sidenav.dummy",
    path: `${APP_PREFIX_PATH}/dummy`,
    component: React.lazy(() => import("views/app-views/dummy")),
  },

  {
    key: "apps.ecommerce",
    path: `${APP_PREFIX_PATH}/ecommerce`,
    component: React.lazy(() => import("views/app-views/e-commerce")),
  },
  {
    key: "apps.ecommerce.add-product",
    path: `${APP_PREFIX_PATH}/ecommerce-add-product`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/add-product")
    ),
  },
  {
    key: "ecommerce-retailer-product",
    path: `${APP_PREFIX_PATH}/ecommerce-retailer-products`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/Retailer-Product")
    ),
  },
  {
    key: "apps.ecommerce.product.all",
    path: `${APP_PREFIX_PATH}/ecommerce-product-all/:id`,
    component: React.lazy(() => import("views/app-views/Inventory/AllProduct")),
  },
  {
    key: "apps.ecommerce.products",
    path: `${APP_PREFIX_PATH}/ecommerce-products`,
    component: React.lazy(() =>
      import("views/app-views/Inventory/ProductListingAll")
    ),
  },
  {
    key: "apps.ecommerce.add-brand",
    path: `${APP_PREFIX_PATH}/ecommerce-add-brand`,
    component: React.lazy(() => import("views/app-views/e-commerce/AddBrand")),
  },
  {
    key: "apps.ecommerce.add-variation",
    path: `${APP_PREFIX_PATH}/ecommerce-product-variation-add`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/Add-Variation")
    ),
  },
  {
    key: "apps.ecommerce.edit-variation",
    path: `${APP_PREFIX_PATH}/ecommerce-product-variation-edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/edit-variation")
    ),
  },
  {
    key: "apps.ecommerce.brand-list",
    path: `${APP_PREFIX_PATH}/ecommerce-product-brand-list`,
    component: React.lazy(() => import("views/app-views/Inventory/AllBrands")),
  },
  {
    key: "apps.ecommerce.brandEdit",
    path: `${APP_PREFIX_PATH}/ecommerce-product-brand-edit/:id`,
    component: React.lazy(() => import("views/app-views/Inventory/EditBrand")),
  },
  {
    key: "apps.ecommerce.variation-list",
    path: `${APP_PREFIX_PATH}/ecommerce-product-variation-list/:id`,
    component: React.lazy(() =>
      import("views/app-views/Inventory/AllVariants")
    ),
  },
  {
    key: "apps.ecommerce.store",
    path: `${APP_PREFIX_PATH}/add-store`,
    component: React.lazy(() => import("views/app-views/Inventory/AddStore")),
  },
  {
    key: "apps.ecommerce.storeListing",
    path: `${APP_PREFIX_PATH}/stores`,
    component: React.lazy(() =>
      import("views/app-views/Inventory/StoreListing")
    ),
  },
  {
    key: "apps.ecommerce.storeEdit",
    path: `${APP_PREFIX_PATH}/edit-store/:id`,
    component: React.lazy(() => import("views/app-views/Inventory/EditStore")),
  },
  {
    key: "edit-inventories",
    path: `${APP_PREFIX_PATH}/edit-inventory/:id`,
    component: React.lazy(() =>
      import("views/app-views/Inventory/EditStoreInventory")
    ),
  },

  {
    key: "apps.ecommerce.assignInventory",
    path: `${APP_PREFIX_PATH}/assigninventory`,
    component: React.lazy(() =>
      import("views/app-views/Inventory/AssignInventory")
    ),
  },

  {
    key: "apps.ecommerce.viewStore",
    path: `${APP_PREFIX_PATH}/ecommerce/store/view`,
    component: React.lazy(() => import("views/app-views/Inventory/ViewStore")),
  },
  {
    key: "apps.ecommerce.edit-product",
    path: `${APP_PREFIX_PATH}/edit-product/:id`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/edit-product")
    ),
  },
  {
    key: "apps.ecommerce.applicationDetails",
    path: `${APP_PREFIX_PATH}/application-details/:id`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/ApplicationDetails")
    ),
  },
  {
    key: "apps.ecommerce.product-list",
    path: `${APP_PREFIX_PATH}/applications-list`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/product-list")
    ),
  },

  {
    key: "apps.ecommerce.orders",
    path: `${APP_PREFIX_PATH}/ecommerce/orders`,
    component: React.lazy(() => import("views/app-views/e-commerce/orders")),
  },
  {
    key: "apps.ecommerce.application",
    path: `${APP_PREFIX_PATH}/add-application`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/Application/Application")
    ),
  },
  {
    key: "ecommerce-product-category",
    path: `${APP_PREFIX_PATH_SETTINGS}/ecommerce-product-category`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/product-category")
    ),
  },
  {
    key: "ecommerce-settings-document-types",
    path: `${APP_PREFIX_PATH_SETTINGS}/document-types`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/Application/DocumentTypes")
    ),
  },
  {
    key: "ecommerce-settings-document-verification-checklist",
    path: `${APP_PREFIX_PATH_SETTINGS}/document-verification-checklist`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/Application/DocVerificationChecklist")
    ),
  },
  {
    key: "ecommerce-settings-document-change-reason",
    path: `${APP_PREFIX_PATH_SETTINGS}/document-change-reasons`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/Application/DocumentChangeReason")
    ),
  },
  {
    key: "ecommerce-settings-document-rejection-reason",
    path: `${APP_PREFIX_PATH_SETTINGS}/document-rejection-reasons`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/Application/DocumentRejectionReason")
    ),
  },
  {
    key: "ecommerce-settings-document-resubmission-reason",
    path: `${APP_PREFIX_PATH_SETTINGS}/document-resubmission-reasons`,
    component: React.lazy(() =>
      import(
        "views/app-views/e-commerce/Application/DocumentResubmissionReason"
      )
    ),
  },
  {
    key: "ecommerce-settings-refrence-verification-checklist",
    path: `${APP_PREFIX_PATH_SETTINGS}/refrence-verification-checklist`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/Application/RefrenceVerification")
    ),
  },
  {
    key: "ecommerce-settings-reference-relation",
    path: `${APP_PREFIX_PATH_SETTINGS}/reference-relation`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/Application/RefrenceRelation")
    ),
  },
  {
    key: "ecommerce-settings-change-due-date-reason",
    path: `${APP_PREFIX_PATH_SETTINGS}/change-due-date-reason`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/Application/ChangeDueDateReason")
    ),
  },
  {
    key: "ecommerce-settings-change-installment-amount-reason",
    path: `${APP_PREFIX_PATH_SETTINGS}/change-installment-amount-reason`,
    component: React.lazy(() =>
      import(
        "views/app-views/e-commerce/Application/ChangeInstalmentamountreason"
      )
    ),
  },
  {
    key: "ecommerce-product-add-category",
    path: `${APP_PREFIX_PATH}/ecommerce-add-product-category`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/add-product-category")
    ),
  },
  {
    key: "apps.ecommerce.application",
    path: `${APP_PREFIX_PATH}/ecommerce/purchaseOrder`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/PurchaseOrder")
    ),
  },
  {
    key: "apps.ecommerce.application",
    path: `${APP_PREFIX_PATH}/ecommerce/paymentDetails`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/PaymentDetails")
    ),
  },
  {
    key: "whatspp.application",
    path: `${APP_PREFIX_PATH}/whatsappApplication`,
    component: React.lazy(() => import("views/app-views/whatsappApplication")),
  },
  {
    key: "view.products-details",
    path: `${APP_PREFIX_PATH}/details`,
    component: React.lazy(() => import("views/app-views/e-commerce/Details")),
  },

  {
    key: "apps-management",
    path: `${APP_PREFIX_PATH}/apps-management`,
    component: React.lazy(() => import("views/app-views/AppsManagement")),
  },
  {
    key: "inventory.management.addInventory",
    path: `${APP_PREFIX_PATH}/inventory/addinventory`,
    component: React.lazy(() =>
      import("views/app-views/Inventory/AddInventory")
    ),
  },

  {
    key: "utils-manage-financing",
    path: `${APP_PREFIX_PATH_SETTINGS}/ecommerce-financing-utils`,
    component: React.lazy(() =>
      import("views/app-views/UtilViews/FinancingUtils")
    ),
  },
  {
    key: "utils-manage-financing",
    path: `${APP_PREFIX_PATH_SETTINGS}/add-ecommerce-financing-utils`,
    component: React.lazy(() =>
      import("views/app-views/UtilViews/AddApplicationChannel")
    ),
  },
  {
    key: "utils-edit-financing-utils",
    path: `${APP_PREFIX_PATH_SETTINGS}/edit-ecommerce-financing-utils/:id`,
    component: React.lazy(() =>
      import("views/app-views/UtilViews/EditApplicationChannel")
    ),
  },
  {
    key: "utils-website-financing-listing",
    path: `${APP_PREFIX_PATH}/ecommerce/website-applications`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/website-application-listing")
    ),
  },
  {
    key: "utils-application-status",
    path: `${APP_PREFIX_PATH_SETTINGS}/ecommerce-application-status`,
    component: React.lazy(() =>
      import("views/app-views/UtilViews/ApplicationStatuses")
    ),
  },
  {
    key: "utils-manage-application-status",
    path: `${APP_PREFIX_PATH_SETTINGS}/add-application-status`,
    component: React.lazy(() =>
      import(
        "views/app-views/UtilViews/ApplicationStatuses/AddApplicationStatus"
      )
    ),
  },
  {
    key: "utils-edit-application-status",
    path: `${APP_PREFIX_PATH_SETTINGS}/edit-application-status/:id`,
    component: React.lazy(() =>
      import(
        "views/app-views/UtilViews/ApplicationStatuses/EditApplicationStatus"
      )
    ),
  },
  {
    key: "utils-manage-add-installment-period-type",
    path: `${APP_PREFIX_PATH_SETTINGS}/add-installment-period-type`,
    component: React.lazy(() =>
      import(
        "views/app-views/UtilViews/InstalmentPeriodsTypes/AddInstallmentPeriodType"
      )
    ),
  },
  {
    key: "utils-manage-installment-period-type",
    path: `${APP_PREFIX_PATH_SETTINGS}/installment-period-type`,
    component: React.lazy(() =>
      import("views/app-views/UtilViews/InstalmentPeriodsTypes")
    ),
  },
  {
    key: "edit-installment-period-type",
    path: `${APP_PREFIX_PATH_SETTINGS}/edit-installment-period-type/:id`,
    component: React.lazy(() =>
      import(
        "views/app-views/UtilViews/InstalmentPeriodsTypes/EditInstalmentPeriodTypes"
      )
    ),
  },
  {
    key: "utils-manage-campaign",
    path: `${APP_PREFIX_PATH_SETTINGS}/ecommerce-catalogue-utils`,
    component: React.lazy(() =>
      import("views/app-views/UtilViews/CatalogueListing")
    ),
  },
  {
    key: "utils-manage-catalogue-add",
    path: `${APP_PREFIX_PATH_SETTINGS}/ecommerce-catalogue-add`,
    component: React.lazy(() =>
      import("views/app-views/UtilViews/AddCatalogue")
    ),
  },
  {
    key: "utils-manage-catalogue-add",
    path: `${APP_PREFIX_PATH_SETTINGS}/ecommerce-catalogue-edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/UtilViews/EditCatalogue")
    ),
  },
  {
    key: "ecommerce-insurance",
    path: `${APP_PREFIX_PATH_SETTINGS}/ecommerce-insurance`,
    component: React.lazy(() => import("views/app-views/UtilViews/Insurance")),
  },
  {
    key: "inventory.management.addPartner",
    path: `${APP_PREFIX_PATH}/inventory/addPartner`,
    component: React.lazy(() => import("views/app-views/Inventory/AddPartner")),
  },
  {
    key: "inventory.management.listing",
    path: `${APP_PREFIX_PATH}/inventory/detailedListing/:id`,
    component: React.lazy(() =>
      import("views/app-views/Inventory/Listing/DetailedListing.js")
    ),
  },
  {
    key: "inventory.management.listing",
    path: `${APP_PREFIX_PATH}/all-inventory`,
    component: React.lazy(() =>
      import("views/app-views/Inventory/Listing/Table/InventoryListingTable")
    ),
  },
  {
    key: "campaign",
    path: `${APP_PREFIX_PATH}/campaign-plan`,
    component: React.lazy(() => import("views/app-views/e-commerce/Campaign")),
  },
  {
    key: "edit-plan",
    path: `${APP_PREFIX_PATH}/edit-plan/:id`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/Campaign/EditCampiagn")
    ),
  },
  {
    key: "campaign.listing",
    path: `${APP_PREFIX_PATH}/all-campaigns`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/Campaign/CampaignListing")
    ),
  },
  {
    key: "campaign.addCampaign",
    path: `${APP_PREFIX_PATH}/add-plans`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/Campaign/AddCampaign/AddCampaign.js")
    ),
  },
  {
    key: "user-manage-allOrganization",
    path: `${APP_PREFIX_PATH}/user-all-organization`,
    component: React.lazy(() =>
      import("views/app-views/UserMangement/AllOrganization")
    ),
  },
  {
    key: "user.all",
    path: `${APP_PREFIX_PATH}/user-all`,
    component: React.lazy(() =>
      import("views/app-views/UserMangement/AllUsers")
    ),
  },
  {
    key: "user.manage",
    path: `${APP_PREFIX_PATH}/add-users`,
    component: React.lazy(() =>
      import("views/app-views/UserMangement/AddUsers")
    ),
  },
  {
    key: "user-manage-userRoles",
    path: `${APP_PREFIX_PATH}/users-roles`,
    component: React.lazy(() => import("views/app-views/UserMangement")),
  },
  {
    key: "user-manage-add-userRoles",
    path: `${APP_PREFIX_PATH}/add-users-roles`,
    component: React.lazy(() => import("views/app-views/UserMangement/Roles")),
  },

  {
    key: "tasdeeq",
    path: `${APP_PREFIX_PATH}/CreditHistory-tasdeeq`,
    component: React.lazy(() => import("views/app-views/CreditHistory")),
  },
  {
    key: "fetch-tasdeeq",
    path: `${APP_PREFIX_PATH}/fetch-CreditHistory`,
    component: React.lazy(() =>
      import("views/app-views/CreditHistory/Tasdeeq")
    ),
  },

  {
    key: "data-check",
    path: `${APP_PREFIX_PATH}/CreditHistory-datacheck`,
    component: React.lazy(() =>
      import("views/app-views/CreditHistory/DataCheck")
    ),
  },

  {
    key: "organization",
    path: `${APP_PREFIX_PATH}/add-organization`,
    component: React.lazy(() =>
      import("views/app-views/OrganizationManagement")
    ),
  },
  {
    key: "deviceInformation",
    path: `${APP_PREFIX_PATH}/device-information/:id`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/ApplicationDetails/DeviceDetails")
    ),
  },
  {
    key: "plan-calculator",
    path: `${APP_PREFIX_PATH}/plan-calculator`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/Campaign/PlansCalculator")
    ),
  },
  {
    key: "partner-application",
    path: `${APP_PREFIX_PATH}/application`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/AddPartnerApplication")
    ),
  },
  {
    key: "add-retailer",
    path: `${APP_PREFIX_PATH}/retailer`,
    component: React.lazy(() => import("views/app-views/Inventory/Retailer")),
  },
  {
    key: "bannerManage",
    path: `${APP_PREFIX_PATH}/websitebanner`,
    component: React.lazy(() =>
      import("views/app-views/websiteManagement/BannerManage")
    ),
  },
  {
    key: "addBanner",
    path: `${APP_PREFIX_PATH}/addBanner`,
    component: React.lazy(() =>
      import("views/app-views/websiteManagement/BannerManage/AddBanner")
    ),
  },
  {
    key: "blueExShipment",
    path: `${APP_PREFIX_PATH}/createshipment/:id`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/ApplicationDetails/Shipment")
    ),
  },
  {
    key: "security-management",
    path: `${APP_PREFIX_PATH}/allprofiles`,
    component: React.lazy(() => import("views/app-views/OutBack/Profiles")),
  },
  {
    key: "edit-product-type",
    path: `${APP_PREFIX_PATH}/ecommerce-add-product-type/:id`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/ProductType/EditProductType")
    ),
  },
  {
    key: "release-devices",
    path: `${APP_PREFIX_PATH}/release`,
    component: React.lazy(() =>
      import("views/app-views/OutBack/ReleaseDevices")
    ),
  },
  {
    key: "add-outback-application",
    path: `${APP_PREFIX_PATH}/add-outback-application`,
    component: React.lazy(() =>
      import("views/app-views/OutBack/OutBackApplication")
    ),
  },
  {
    key: "edit-banner",
    path: `${APP_PREFIX_PATH}/edit-banner/:id`,
    component: React.lazy(() =>
      import("views/app-views/websiteManagement/BannerManage/EditBanner")
    ),
  },
  {
    key: "Api-credentials",
    path: `${APP_PREFIX_PATH}/api/credentials`,
    component: React.lazy(() => import("views/app-views/ApiCredential")),
  },
  {
    key: "attribute-detail",
    path: `${APP_PREFIX_PATH_SETTINGS}/attribute-detail`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/Attributes-details")
    ),
  },
  {
    key: "add-attribute",
    path: `${APP_PREFIX_PATH_SETTINGS}/add-attribute`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/Attributes-details/AddAttribute")
    ),
  },
  {
    key: "financing-company",
    path: `${APP_PREFIX_PATH_SETTINGS}/financing-company`,
    component: React.lazy(() =>
      import("views/app-views/UtilViews/FinancingCompany")
    ),
  },
  {
    key: "product-type",
    path: `${APP_PREFIX_PATH_SETTINGS}/ecommerce-product-type`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/ProductType")
    ),
  },
  {
    key: "add-product-type",
    path: `${APP_PREFIX_PATH}/ecommerce-add-product-type`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/Product-type")
    ),
  },
  {
    key: "product-type",
    path: `${APP_PREFIX_PATH}/add-product-type`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/Product-type")
    ),
  },
  {
    key: "add-product-id",
    path: `${APP_PREFIX_PATH}/add-product/:id`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/ProductForm")
    ),
  },
  {
    key: "edit-attribute",
    path: `${APP_PREFIX_PATH_SETTINGS}/edit-attribute/:id`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/Attributes-details/EditAttribute")
    ),
  },
  {
    key: "uom",
    path: `${APP_PREFIX_PATH_SETTINGS}/unit-of-measurement`,
    component: React.lazy(() => import("views/app-views/UtilViews/UOM")),
  },
  {
    key: "new-application-details-id",
    path: `${APP_PREFIX_PATH}/website-application/:id`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/website-application-details")
    ),
  },
  {
    key: "partner-store",
    path: `${APP_PREFIX_PATH}/store`,
    component: React.lazy(() => import("views/app-views/e-commerce/Store")),
  },
  {
    key: "product-plans-id",
    path: `${APP_PREFIX_PATH}/product/:id`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/ProductPlans")
    ),
  },
  {
    key: "product-promo-codes",
    path: `${APP_PREFIX_PATH}/promo-codes`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/PromoCodes")
    ),
  },
  {
    key: "create-insurance",
    path: `${APP_PREFIX_PATH}/create-insurance`,
    component: React.lazy(() => import("views/app-views/e-commerce/Insurance")),
  },

  // payments routes
  {
    key: "payment-cash",
    path: `${APP_PREFIX_PATH_SETTINGS}/payment/9`,
    component: React.lazy(() =>
      import("views/app-views/payment/Cash")
    ),
  },
  {
    key: "payment-bank-transfer",
    path: `${APP_PREFIX_PATH_SETTINGS}/payment/8`,
    component: React.lazy(() =>
      import("views/app-views/payment/BankTransfer")
    ),
  },
  {
    key: "payment-payfast",
    path: `${APP_PREFIX_PATH_SETTINGS}/payment/5`,
    component: React.lazy(() =>
      import("views/app-views/payment/PayFast")
    ),
  },
  {
    key: "payment-payfast-add",
    path: `${APP_PREFIX_PATH_SETTINGS}/add/payfast/5`,
    component: React.lazy(() =>
      import("views/app-views/payment/AddPayFast")
    ),
  },
  {
    key: "payment-jazzcash",
    path: `${APP_PREFIX_PATH_SETTINGS}/payment/4`,
    component: React.lazy(() =>
      import("views/app-views/payment/JazzCash")
    ),
  },
  {
    key: "payment-jazzcash-add",
    path: `${APP_PREFIX_PATH_SETTINGS}/add/jazzcash/4`,
    component: React.lazy(() =>
      import("views/app-views/payment/AddJazzCash")
    ),
  },
  {
    key: "payment-easypaisa",
    path: `${APP_PREFIX_PATH_SETTINGS}/payment/6`,
    component: React.lazy(() =>
      import("views/app-views/payment/EasyPaisa")
    ),
  },
  {
    key: "payment-easypaisa-add",
    path: `${APP_PREFIX_PATH_SETTINGS}/add/easypaisa/6`,
    component: React.lazy(() =>
      import("views/app-views/payment/AddEasyPaisa")
    ),
  },
  {
    key: "payment-easypaisa-edit",
    path: `${APP_PREFIX_PATH_SETTINGS}/edit/easypaisa/:paymentMethodId`,
    component: React.lazy(() =>
      import("views/app-views/payment/AddEasyPaisa")
    ),
  },
  {
    key: "payment-jazzcash-edit",
    path: `${APP_PREFIX_PATH_SETTINGS}/edit/jazzcash/:paymentMethodId`,
    component: React.lazy(() =>
      import("views/app-views/payment/AddJazzCash")
    ),
  },
  {
    key: "payment-payfast-edit",
    path: `${APP_PREFIX_PATH_SETTINGS}/edit/payfast/:paymentMethodId`,
    component: React.lazy(() =>
      import("views/app-views/payment/AddPayFast")
    ),
  },
  {
    key: "apps.ecommerce.insurancesClaim",
    path: `${APP_PREFIX_PATH}/ecommerce/insurancesClaim`,
    component: React.lazy(() =>
      import("views/app-views/e-commerce/InsurancesClaim")
    ),
  },


];
